/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// Capturing and storing UTM tags for form use
if(window){
    window.utm_tags = {};
    if(getParameter('utm_campaign') != ''){
        window.utm_tags.campaign = getParameter('utm_campaign')
    }
    if (getParameter('utm_source') != '') {
        window.utm_tags.source = getParameter('utm_source')
    }
    if (getParameter('utm_medium') != '') {
        window.utm_tags.medium = getParameter('utm_medium')
    }
    if (getParameter('utm_term') != '') {
        window.utm_tags.term = getParameter('utm_term')
    }
}

function getParameter(theParameter) {
    var params = window.location.search.substr(1).split('&');
    for (var i = 0; i < params.length; i++) {
        var p = params[i].split('=');
        if (p[0] == theParameter) {
            return decodeURIComponent(p[1]);
        }
    }
    return false;
}